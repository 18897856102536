import React from 'react'
import '../css/linkBlock.css';
export default function CustomLinkBlock(props) {
  //  height="100%" width="100%"
   // width="350" height="150"
  return (
    <div class="linkBlock bg-white border border-primary border-2 m-1  w-100 ">
        <div class=" row m-0 w-100 h-100">
            <div class="col-3 d-flex">
                <img src={props.img} alt=" link lorem" width="50"  height="50"class=" img-fluid  "/>
                </div>
                <div class="col-9 d-flex justify-content-start align-items-center">
                <div class="">
                <a href={props.link} class=" active text-black text-start fs-6  text-decoration-none">{ props.name}</a>
                </div>             
            </div>
        </div>
    </div>
  )
}
