// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import Navbar from './view components/Navbar';
import Home from './view components/Home';
import OfficeStaff from './view components/OfficeStaff';
import Gallery from './view components/News/Gallery';

import ContactUs from './view components/ContactUs';
import AboutUs from './view components/AboutUs';
import Footer from './view components/Footer';
// import NotificationBlock from './components/NotificationBlock';
// import CustomLinkBlock from './components/CustomLinkBlock';
// import Carousel from './components/Carousel';
import Registration from './view components/Registration';

 // vh-100 overflow-auto  to make make container scrollable when content over flows

import {BrowserRouter as Router,Route,Routes } from 'react-router-dom';
function App() {

//   FirebaseApp.initializeApp(/*context=*/ this);
// FirebaseAppCheck firebaseAppCheck = FirebaseAppCheck.getInstance();
// firebaseAppCheck.installAppCheckProviderFactory(
//         PlayIntegrityAppCheckProviderFactory.getInstance());
  return (
    <Router>
    <div class="px-1 bg-white">     
      <Navbar/>
      <Home/>
      {/* <AboutUs/> */}
      {/* <OfficeStaff /> */}
      {/* <Gallery/> */}
      {/* <ContactUs/> */}
      
      <Footer/>
    {/*  <CustomLinkBlock link="/" name="lorem ipsum" /> */}
         
</div>
    </Router>
  );
}

export default App;
