import React from 'react'
import '../css/Home.css'
import CustomLinkBlock from '../components/CustomLinkBlock';
import CarouselComponent from '../components/CarouselComponent';
import NotificationBlock from '../components/NotificationBlock';
import AboutUs from './AboutUs';
import Repeatimage from '../components/Repeatimage';
 function Home() {
  return (
    <div class="home p-1 " id="home" >
   <div class=" p-1 ">
   <div class='row W-100 '>
   <div class=" col-lg-3 col-md-12 col-sm-12 bg-white">
        
      <CustomLinkBlock link="/" name="D.B.C.L.C"  img="/assets/Images/dbclc-navlogo.png"/>
      <CustomLinkBlock link="http://catechismcenter.dbclc.com/" name="Archdiocesan Catechism Center" img="/assets/Images/dbclc-navlogo.png" />
      <CustomLinkBlock link="http://theologyinstitute.dbclc.com/" name="Theology" img="/assets/Images/Theology-2.png"/>
      <CustomLinkBlock link="http://layinstitute.dbclc.com/" name="Institute for Lay Leadership Training" img="/assets/Images/dbclc-navlogo.png"/>
      <CustomLinkBlock link="http://excelacademy.dbclc.com/" name="Excel Academy" img="/assets/Images/excel-academy.png"/>
      <CustomLinkBlock link="/" name="Library" img="/assets/Images/dbclc-navlogo.png"/>
      <CustomLinkBlock link="/" name="Religious Stall" img="/assets/Images/dbclc-navlogo.png"/>
      <CustomLinkBlock link="/" name="Auditorium" img="/assets/Images/dbclc-navlogo.png"/>
      <CustomLinkBlock link="/" name="Publications" img="/assets/Images/dbclc-navlogo.png" />
      
        </div>
        <div  class=" col-md-12 col-lg-9 col-sm-12 ">
          <div class="row ">
            <div class="col-lg-9 col-md-9 col-sm-12 p-0">
            <CarouselComponent/>
            </div>
            <div  class="col-lg-3 col-md-3 col-sm-12 p-0 ">
            <Repeatimage/>
          </div>      
         </div >  
         <div class="row bg-white  border-primary">
          {/* <div class="col-lg-3  col-md-3 col-sm-12 d-flex align-items-center justify-content-center p-3
           ">
             <img src="/assets/Images/dbclc-navlogo.png" class=" img-home  " alt="test image" id="aboutimg" />
          </div> */}        
          </div>    
          <div class="row ">
            <div class="col-lg-9 col-md-12 col-sm-12 p-1 ">
            {/* <div  class=" col-lg-8 col-md-8 col-sm-12"> */}
          <p  class="fs-6 text py-3"> 
          D.B.C.L.C was inaugurated by Mar Joseph Kundukulam on 1st May, 1984. It was then the sole centre for biblical, Catechetical and liturgical ministry of the Diocese of Trichur. Today D.B.C.L.C includes the office of the Archdiocesan Catechetical Centre, Catechetical Book Stall, Institute of Theology and Archdiocesan Library. The chapel, auditorium and lecture halls make the centre a venue for gatherings, meetings, seminars and formation courses.
          </p>
           {/* </div> */}
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12  p-2">
             {/* <NotificationBlock/>  */}
            </div>
            </div>
            <div class="row ">
            <div class="col-lg-3 col-md-9 col-sm-12 p-0">
  <div class="border border-2 border-black img-frame p-1">
  <img src={'/assets/director/88.jpg'} alt="Slideshow"  width="100%" height="250"/>
  <p class="fs-6 fw-bold text-black m-1 text-center">Fr. Francis Aloor</p>
  <p class="fs-6 text-primary  m-1 font-italic text-center">Director </p>
  </div>
            </div>
            <div class="col-lg-3 col-md-9 col-sm-12 p-0">
            <div class="border border-2 border-black img-frame p-1">
  <img src={'/assets/director/290_734854818.jpg'} alt="Slideshow"  width="100%" height="250"/>
  <p class="fs-6 fw-bold text-black m-1 text-center">Fr. Chalissery Lijo</p>
  <p class="fs-6 text-primary  m-1 font-italic text-center">Asst. Director</p>
  </div>
            </div>
            <div class="col-lg-3 col-md-9 col-sm-12 p-0">
            <div class="border border-2 border-black img-frame p-1">
  <img src={'/assets/director/407.jpg'} alt="Slideshow"  width="100%" height="250"/>
  <p class="fs-6 fw-bold text-black m-1 text-center">Fr. Muringathery Sijo</p>
  <p class="fs-6 text-primary  m-1 font-italic text-center">Asst. Director</p>
  </div>
            </div>
            <div  class="col-lg-3 col-md-3 col-sm-12 p-0  d-flex justify-content-center align-items-center">
            <div class="border border-2 border-black img-frame p-1" >
  <img src={'/assets/director/405_2125780018.jpg'} alt="Slideshow"  width="100%" height="250"/>
  <p class="fs-6 fw-bold text-black m-1 text-center">Fr. Malokkaran Jickson</p>
  <p class="fs-6 text-primary  m-1 font-italic text-center">Asst. Director</p>
  </div>
          </div>      
         </div >       
       </div>
      
    </div>
   
   </div>
  </div>    
  )
}

export default Home;