import React from 'react'
// import ServicesCard from '../components/ServicesCard';
// import'../css/footer.css';
 function Footer() {
  return (
    <div class=" py-3 bg-black" id="footer"> 
    <div>
   
<div class=" row bg-black p-0 m-0">
    {/* <div class="col-md-6 col-lg-4 d-flex align-items-center justify-content-center " > 
    <a class=" m-2 text-decoration-none " href="/"> <i class="  fa-brands fa-facebook text-white fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none " href="/"> <i class="  fa-brands fa-twitter text-white  fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none " href="/"> <i class="  fa-brands fa-instagram text-white fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none " href="/"> <i class="  fa-brands fa-linkedin text-white fa-2x"></i>  </a>
    </div> */}
    <div class=" col-md-12   col-sm-12 col-lg-12 d-flex align-items-center justify-content-center ">
      <div class="">
      <p  class=" fs-6 fw-bold  font-monospace text-white text-center">© Copyright 2023 Designed by @Apex Data Technologies All Rights Reserved . </p>     
     </div>    
    </div>
  </div>
      </div>       
    </div>
  )
}
export default Footer;