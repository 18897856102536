import React, { useState, useEffect } from 'react';
// import image1 from '../assets/jesus.jpg'
// import image1 from '../assets/jesus1.jpg'
// import image2 from '../assets/jesus2.jpg'
// import image3 from '../assets/jesus3.jpg'



const AutoImageChange = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const images = [
    {img:'/assets/bishop/Pope-Francis.jpg',
    name:'Pope Francis',
    title:'Pope'
  }, 
  {
    img:'/assets/bishop/alenchery.jpg',
    name:'Mar George Cardinal Alencherry',
    title:'Archbishop'
  }, 
    {
    img:'/assets/bishop/image1.jpg',
    name:'Mar Andrews Thazhath',
    title:'Archbishop'
  },
  {
    img:'/assets/bishop/image2.jpeg',
    name:'Mar Tony Neelankavil',
    title:'Aux. Bishop & Proto-Syncellus'

  }
    // '/assets/bishop/Pope-Francis.jpg',
    // '/assets/bishop/image2.jpeg',
    // Add more image URLs as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the next image index
      const nextImageIndex = (currentImageIndex + 1) % images.length;
      console.log(nextImageIndex);
      setCurrentImageIndex(nextImageIndex);
    }, 5000); // Change image every 5 seconds (adjust as needed)

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentImageIndex, images.length]);

  return <div class="border border-2 border-black">
  <img src={images[currentImageIndex].img} alt="Slideshow"  width="100%" height="325"/>
  <p class="fs-6 fw-bold text-black m-1 text-center">{images[currentImageIndex].name} </p>
  <p class="fs-6 text-primary  m-1 font-italic text-center">{images[currentImageIndex].title} </p>

  </div>;
};

const Repeatimage =()=>{
    return(
        <>
        <div class="p-1 ">
            <AutoImageChange/>
        </div>
        </>
    )
}
export default Repeatimage;