import react from 'react';
import { Link } from 'react-router-dom';
   import'../css/navbar.css';
 function Navbar() {
  return (
   // fixed-top for making scrollbar fixed at the top
   // sticky-top for sticky component at tthe top  navbar-expand-lg
    <nav class="navbar  navbar-expand-lg shadow-sm justify-content-center p-0 " id="navbar">
        <div class="row m-0 w-100 ">
              <div  class="col-lg-12 logo-container d-flex w-100  align-items-end  justify-content-between ">
              <div class=" col-lg-6 col-md-12 col-sm-6 d-flex w-100   justify-content-between  "> 
              < div class=" d-flex align-self-end">
              <Link class="navbar-brand fs-2 align-self-center" to="/">
             <img src="/assets/Images/dbclc-logotb.png" alt="university" width="120" height="120" />
              </Link>
              <div class="">
              <p class=" logo-text align-self-end m-0 ">DIOCESAN BIBLICAL CATECHETICAL LITURGICAL CENTRE <br/> D B C L C</p>
              <p class="logo-moto m-0 "> GO AND PREACH TO ALL THE NATIONS</p>
              </div>

              </div>           
            <span class-=" ms-5 align-self-center ">
            <span
                class="navbar-toggler mr-auto "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="true"
                aria-label="Toggle navigation">
                  <i class="  fa-solid fa-bars text-white navbar-toggler-icon"></i>
                {/* <span class="navbar-toggler-icon rounded"></span> */}
              </span>
            </span>          
          
           </div> 
           
              </div>           
           <div class=" collapse col-lg-12 col-sm-12 navbar-collapse justify-content-center align-items-center bg-white " id="navbarNav">
        <ul class="navbar-nav py-1 ">
        <li class="nav-item  active px-2">
        {/* <Link  class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/">Home</Link>  */}
        <a href="/" class="nav-link active  fs-5 fw-bold  ">Home</a>      
        </li> 
        <div class="vr"></div>     
        <li class="nav-item px-2">
        {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/services">Services</Link>   */}
        <a href="/" class="nav-link active  fs-5 fw-bold  ">Office</a>
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2 ">
        {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/about">About</Link>  */}
        <a href="/" class="nav-link active  fs-5 fw-bold  ">About</a>
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2 ">
        {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/team">Team</Link> */}
        <a href="/" class="nav-link active   fs-5 fw-bold  ">GalleryNews</a> 
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2">
          {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
          <a href="/" class="nav-link active   fs-5 fw-bold ">Contact</a>
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2">
          {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
          <a href="/" class="nav-link active   fs-5 fw-bold ">Programs</a>
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2">
          {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
          <a href="/" class="nav-link active  fs-5 fw-bold ">Registration</a>
        </li>
        <div class="vr"></div>
        <li class="nav-item px-2 ">
          {/* <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link>  */}
          <a href="/" class="nav-link active fs-5 fw-bold ">Auditorium</a>
        </li>      
      </ul> 
        </div>
      </div>          
  </nav>
 
 
  )
}
export default Navbar;