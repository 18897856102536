import React,{useEffect} from 'react'
import '../css/carouselBlock.css';
const CarouselComponent =()=> {

  useEffect(()=>{

  },[]);
  return (
<div class="carouselblock  m-0 p-1 ">
<div id="carouselControls" class="carousel slide " data-bs-ride="carousel">
  <div class="carousel-inner h-25">
    <div class="carousel-item active">
      <img src="/assets/Images/dbclc-2.png" class="d-block  img-fluid w-100" alt="img1" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">first slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/img-1.png" class="d-block img-fluid w-100 " alt="img2" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">second slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/img-3.png" class="d-block img-fluid w-100" alt="img3" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">second slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-3.jpg" class="d-block img-fluid w-100" alt="img4" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">third slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-4.jpg" class="d-block img-fluid w-100" alt="img5" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">fourth slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-5.jpg" class="d-block img-fluid w-100" alt="img6" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">fourth slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-6.jpg" class="d-block img-fluid w-100" alt="img7" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">fourth slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-7.png" class="d-block img-fluid w-100" alt="img8" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">fourth slide label</h5> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/Images/dbclc-10.png" class="d-block img-fluid w-100" alt="img9" />
      <div class="carousel-caption d-none d-md-block">
      {/* <h5 class=" text-black">fourth slide label</h5> */}
      </div>
    </div>
  </div>
  <button class="carousel-control-prev " type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
    <span class="carousel-control-next-icon  " aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
   
 
  )
}
export default CarouselComponent;
   
{/* //     <div class=" carouselblock p-1">
//     <div id="carouselcontainer " class="carousel slide active" data-bs-ride="carousel">
//   <div class="carousel-inner">
//     <div class="carousel-item active">
//       <img src="/assets/dumy.png" class="d-block w-100 img-fluid" alt="image1" />
//       <div class="carousel-caption d-none d-md-block">
//         <h5 class=" text-black">First slide label</h5>
//        
//       </div>
//     </div>
//     <div class="carousel-item ">
//       <img src="/assets/dumy.png" class="d-block w-100 img-fluid" alt="image2" />
      
//         <div class="carousel-caption d-none d-md-block">
//         <h5 class=" text-black">second slide label</h5>
//         
//       </div>
      
//     </div>
//     <div class="carousel-item ">
//       <img src="/assets/dumy.png" class="d-block w-100 img-fluid" alt="image3" />
//       <div class="carousel-caption d-none d-md-block">
//         <h5 class=" text-black" >third slide label</h5>
//        
//       </div>
//     </div>
//   </div>
//   <button class="carousel-control-prev" type="button" data-bs-target="#carouselcontainer" data-bs-slide="prev">
//     <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span class="visually-hidden">Previous</span>
//   </button>
//   <button class="carousel-control-next" type="button" data-bs-target="#carouselcontainer" data-bs-slide="next">
//     <span class="carousel-control-next-icon" aria-hidden="true"></span>
//     <span class="visually-hidden">Next</span>
//   </button>
// </div>      
// </div> */}